import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "p-grid"
}
const _hoisted_2 = { class: "p-col-6" }
const _hoisted_3 = {
  key: 0,
  class: "p-col-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrainSpinner = _resolveComponent("BrainSpinner")!
  const _component_ModuleHeader = _resolveComponent("ModuleHeader")!
  const _component_SyllabusDetailHeader = _resolveComponent("SyllabusDetailHeader")!
  const _component_ClassAgendaTable = _resolveComponent("ClassAgendaTable")!
  const _component_SelectedClassPlanCard = _resolveComponent("SelectedClassPlanCard")!
  const _component_ClassModal = _resolveComponent("ClassModal")!
  const _component_HomeworkModal = _resolveComponent("HomeworkModal")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_BrainSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_component_ModuleHeader, {
          key: 1,
          title: `Contenido/Curso/${_ctx.coursePlan?.information.name}/Versión ${_ctx.syllabus?.version}`
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_SyllabusDetailHeader, {
              class: "p-mb-3",
              syllabus: _ctx.syllabus
            }, null, 8, ["syllabus"]),
            _createVNode(_component_ClassAgendaTable, {
              onDisplayClassModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.displayClassModal = true)),
              selection: _ctx.selectedClassPlan,
              "onUpdate:selection": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedClassPlan = $event)),
              classAgenda: _ctx.classPlans,
              syllabusInUse: _ctx.isActiveSyllabus,
              onOnEditClassPlan: _ctx.onEditClassPlan,
              onOnDeleteClassPlan: _ctx.showConfirmDeleteClassPlan,
              onOnReorder: _ctx.reorderClassAgenda
            }, null, 8, ["selection", "classAgenda", "syllabusInUse", "onOnEditClassPlan", "onOnDeleteClassPlan", "onOnReorder"])
          ]),
          (_ctx.selectedClassPlan)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_SelectedClassPlanCard, {
                  onEditHomework: _ctx.onEditHw,
                  onOpenHomeworkModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.displayHomeworkModal = true)),
                  onDeleteHomeworkPlan: _ctx.showConfirmDeleteHomeworkPlan,
                  selectedClassPlan: _ctx.selectedClassPlan,
                  homeworkPlans: _ctx.selectedHomeworkPlans,
                  loadingHomeworks: _ctx.loadingHomeworks
                }, null, 8, ["onEditHomework", "onDeleteHomeworkPlan", "selectedClassPlan", "homeworkPlans", "loadingHomeworks"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ClassModal, {
      onOnEdit: _ctx.editClassPlan,
      onOnSave: _ctx.assignOrderAndCreateClassPlan,
      visible: _ctx.displayClassModal,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_ctx.displayClassModal = $event)),
      editingClassPlan: _ctx.editClassTarget
    }, null, 8, ["onOnEdit", "onOnSave", "visible", "editingClassPlan"]),
    _createVNode(_component_HomeworkModal, {
      onOnSave: _ctx.createHomeworkPlan,
      onOnEdit: _ctx.editHomeworkPlan,
      visible: _ctx.displayHomeworkModal,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => (_ctx.displayHomeworkModal = $event)),
      editingHomeworkPlan: _ctx.editHwTarget
    }, null, 8, ["onOnSave", "onOnEdit", "visible", "editingHomeworkPlan"]),
    _createVNode(_component_ConfirmDialog, {
      breakpoints: {'960px': '75vw', '640px': '100vw'},
      style: {width: '50vw'}
    })
  ], 64))
}