
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  Ref,
  ref,
  toRefs,
  watch
} from 'vue'
import Menu from 'primevue/menu'
import { ClassPlan } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { ReorderValue } from '@/models/sorting/reorder'
import { MenuItem } from 'primevue/menuitem'
import { useToast } from 'primevue/usetoast'
import {
  ToastLife,
  ToastSeverities,
  ToastSummaries
} from '@/models/components/toast/enums'

export default defineComponent({
  components: {
    Menu
  },
  props: {
    classAgenda: {
      type: Array as PropType<ClassPlan[]>,
      default: () => []
    },
    isActiveSyllabus: {
      type: Boolean
    }
  },
  emits: [
    'displayClassModal',
    'onEditClassPlan',
    'onDeleteClassPlan',
    'onReorder'
  ],
  setup (props, ctx) {
    const menuRef = ref()
    const toast = useToast()
    const classAgendaRef = computed<ClassPlan[]>(() => props.classAgenda)
    const { isActiveSyllabus } = toRefs(props)
    const items = ref<MenuItem[]>([])
    const modifyIndexes = ref<Map<string, ReorderValue>>(new Map())
    const classAgendaReference = ref<ClassPlan[]>()
    const classDragged = ref<ClassPlan>()

    onMounted(() => {
      modifyIndexes.value = new Map()
      classAgendaReference.value = classAgendaRef.value
    })

    const checkModified = (cp: ClassPlan) => {
      return classDragged.value?._id === cp._id ? 'animationChange' : null
    }

    const rowReorder = (event: {
      originalEvent: Event
      dragIndex: number
      dropIndex: number
      value: ClassPlan[]
    }) => {
      if (!isActiveSyllabus.value) {
        for (const cp of event.value) {
          const currentIndex = classAgendaRef.value?.findIndex(
            i => cp._id === i._id
          )
          const indexPostReorder = event.value.findIndex(i => cp._id === i._id)
          if (currentIndex !== indexPostReorder) {
            modifyIndexes.value.set(cp._id, {
              initialOrder: currentIndex as number,
              finalOrder: indexPostReorder as number
            })
          }
        }
        classDragged.value = classAgendaRef.value[event.dragIndex]
        ctx.emit('onReorder', [
          modifyIndexes.value,
          event.value[event.dropIndex]
        ])
        classAgendaReference.value = event.value
        setInterval(() => {
          // This reactives animation in case of another index modification
          classDragged.value = undefined
        }, 3000)
      } else {
        toast.add({
          severity: ToastSeverities.Warn,
          summary: ToastSummaries.Warn,
          detail:
            '¡Ups! No se puede cambiar el orden de un programa en uso. Crea una nueva versión en su lugar',
          life: ToastLife.Default
        })
      }
    }
    watch(classAgendaRef, () => {
      classAgendaReference.value = classAgendaRef.value
    })

    return {
      menuRef,
      items,
      rowReorder,
      classAgendaReference,
      checkModified,
      toggleMenu: (event: MouseEvent, classPlan: ClassPlan) => {
        items.value = [
          {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: () => {
              ctx.emit('onEditClassPlan', classPlan)
              const i = classAgendaReference.value?.findIndex(
                cp => cp._id === classPlan._id
              )
              if (i && i !== -1) {
                (classAgendaReference.value as ClassPlan[])[i] = classPlan
              }
            }
          },
          {
            label: 'Eliminar',
            icon: 'pi pi-trash',
            command: () => {
              ctx.emit('onDeleteClassPlan', classPlan._id)
              classAgendaReference.value = classAgendaReference.value?.filter(
                cp => cp._id !== classPlan._id
              )
            }
          }
        ] as MenuItem[]
        menuRef.value?.toggle(event)
      }
    }
  }
})
