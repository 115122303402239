
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { useModalForm } from '@/hooks/useModalForm'
import { HomeworkPlan } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { HomeworkType } from '@/models/academic-definitions/enums'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default defineComponent({
  props: {
    editingHomeworkPlan: {
      type: Object as PropType<HomeworkPlan>,
      required: false
    }
  },
  emits: ['onSave', 'onEdit', 'update:visible'],
  setup (props, ctx) {
    const defaultFields = computed(() => props.editingHomeworkPlan)

    const { modalTitle, onShow, onHide, formValues } = useModalForm({
      formInitialState: {
        name: '',
        slideUrl: '',
        description: '',
        slug: '',
        published: false,
        type: ''
      },
      title: 'Nuevo desafío'
    })

    const validations = {
      name: { required },
      type: { required }
    }

    watch(defaultFields, () => {
      if (defaultFields.value) {
        console.log(defaultFields.value)
        Object.assign(formValues, defaultFields.value)
      }
    })

    const v$ = useVuelidate(validations, formValues as { name: unknown, type: unknown })
    const submitted = ref<boolean>(false)

    return {
      formValues,
      onShow,
      onHide,
      modalTitle,
      HomeworkType,
      submitted,
      v$,
      onSubmit: (isFormValid: boolean) => {
        submitted.value = true
        if (!isFormValid) {
          return
        }
        if (defaultFields.value) {
          ctx.emit('onEdit', formValues)
        } else {
          ctx.emit('onSave', formValues)
        }
        ctx.emit('update:visible', false)
      }
    }
  }
})
