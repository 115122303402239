
import { ClassPlanAPI } from '@/api/academic-definitions/class-plan.api'
import { CoursePlanAPI } from '@/api/academic-definitions/course-plan.api'
import { SyllabusAPI } from '@/api/academic-definitions/syllabus.api'
import { HomeworkPlanAPI } from '@/api/academic-definitions/homework-plan.api'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { ClassPlan, HomeworkPlan, Syllabus } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import SyllabusDetailHeader from '../../../components/SyllabusDetailHeader.vue'
import ClassAgendaTable from '../../../components/ClassAgendaTable.vue'
import SelectedClassPlanCard from '../../../components/SelectedClassPlanCard.vue'
import ClassModal from '../../../components/ClassModal.vue'
import HomeworkModal from '../../../components/HomeworkModal.vue'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { ToastSeverities } from '@/models/components/toast/enums'
import { ReorderEntity, ReorderValue } from '@/models/sorting/reorder'
import { SyllabusWithExpirateDate } from '@/models/academic-definitions/interfaces/composition/SyllabusWithExpirateDate'

export default defineComponent({
  components: {
    SyllabusDetailHeader,
    ClassAgendaTable,
    SelectedClassPlanCard,
    ClassModal,
    HomeworkModal
  },
  setup () {
    const { params: { planId, syllabusId } } = useRoute()
    const confirm = useConfirm()
    const toast = useToast()
    const syllabus = ref<Syllabus>()
    const syllabumInUse = ref<SyllabusWithExpirateDate[]>()
    const isActiveSyllabus = computed(() => syllabumInUse.value?.find(s => s.id === syllabus.value?.id))
    const coursePlan = ref<CoursePlan>()
    const classPlans = ref<ClassPlan[]>([])
    const loading = ref<boolean>(false)
    const displayClassModal = ref(false)
    const displayHomeworkModal = ref(false)
    const editClassTarget = ref<ClassPlan>()
    const editHwTarget = ref<HomeworkPlan>()
    const selectedClassPlan = ref<ClassPlan>()
    const loadingHomeworks = ref<boolean>()
    const selectedHomeworkPlans = ref<HomeworkPlan[]>()

    onMounted(async () => {
      try {
        loading.value = true
        coursePlan.value = await CoursePlanAPI.findById(planId as string)
        syllabus.value = await SyllabusAPI.findById(syllabusId as string)
        syllabumInUse.value = await SyllabusAPI.findActiveVersions(planId as string)
        classPlans.value = await ClassPlanAPI.findAll(syllabusId as string)
      } catch (e) {
        console.error(e)
      } finally {
        loading.value = false
      }
    })
    watch(selectedClassPlan, async () => await loadHomeworks())

    const loadHomeworks = async () => {
      try {
        loadingHomeworks.value = true
        selectedHomeworkPlans.value = await HomeworkPlanAPI.findAll(selectedClassPlan.value?._id as string)
      } catch (e) {
        console.error(e)
      } finally {
        loadingHomeworks.value = false
      }
    }

    const assignOrderAndCreateClassPlan = async (form: Partial<ClassPlan>) => {
      const cpDTO = { ...form }
      let maxOrder = 0
      classPlans.value?.map((c) => {
        if (c.order && c.order > maxOrder) {
          maxOrder = c.order
        }
        return 0
      })
      cpDTO.order = maxOrder + 1
      cpDTO.syllabusId = syllabusId as string
      try {
        await ClassPlanAPI.create(cpDTO)
        classPlans.value = await ClassPlanAPI.findAll(syllabusId as string)
        toast.add({
          severity: ToastSeverities.Success,
          detail: '¡Clase creada 🤩🤩!',
          life: 1500
        })
      } catch (e) {
        console.error(e)
        toast.add({
          severity: ToastSeverities.Error,
          detail: 'Error al crear la clase 😪😪',
          life: 1500
        })
      }
    }

    const deleteClassPlan = async (id: string) => {
      try {
        await ClassPlanAPI.delete(id)
        classPlans.value = await ClassPlanAPI.findAll(syllabusId as string)
        if (selectedClassPlan.value?._id === id) {
          selectedClassPlan.value = undefined
        }
        toast.add({
          severity: ToastSeverities.Success,
          detail: '¡Clase eliminada!',
          life: 1500
        })
      } catch (e) {
        console.error(e)
        toast.add({
          severity: ToastSeverities.Error,
          detail: 'Error al eliminar la clase 😪😪',
          life: 1500
        })
      }
    }

    const deleteHomeworkPlan = async (id: string) => {
      try {
        await HomeworkPlanAPI.delete(id)
        loadingHomeworks.value = true
        await loadHomeworks()
        toast.add({
          severity: ToastSeverities.Success,
          detail: '¡Desafío eliminado!',
          life: 1500
        })
      } catch (e) {
        console.error(e)
        toast.add({
          severity: ToastSeverities.Error,
          detail: 'Error al eliminar el desafío 😪😪',
          life: 1500
        })
      } finally {
        loadingHomeworks.value = false
      }
    }

    const editClassPlan = async (dto: ClassPlan) => {
      try {
        await ClassPlanAPI.update(dto, dto._id)
        classPlans.value = await ClassPlanAPI.findAll(syllabusId as string)
        toast.add({
          severity: ToastSeverities.Success,
          detail: '¡Cambios guardados!',
          life: 1500
        })
      } catch (e) {
        console.error(e)
        toast.add({
          severity: ToastSeverities.Error,
          detail: 'Error al editar la clase 😪😪',
          life: 1500
        })
      } finally {
        editClassTarget.value = undefined
      }
    }

    const editHomeworkPlan = async (dto: HomeworkPlan) => {
      try {
        await HomeworkPlanAPI.update(dto, dto.id)
        loadingHomeworks.value = true
        await loadHomeworks()
        toast.add({
          severity: ToastSeverities.Success,
          detail: '¡Cambios guardados!',
          life: 1500
        })
      } catch (e) {
        console.error(e)
        toast.add({
          severity: ToastSeverities.Error,
          detail: 'Error al editar el desafío 😪😪',
          life: 1500
        })
      } finally {
        editHwTarget.value = undefined
        loadingHomeworks.value = false
      }
    }

    const showConfirmDeleteClassPlan = (syllabusId: string) => {
      confirm.require({
        message: '¿Estás seguro/a de eliminar esta clase?',
        header: '¡Alto ahí!',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          deleteClassPlan(syllabusId)
        },
        reject: () => {
          confirm.close()
        }
      })
    }

    const showConfirmDeleteHomeworkPlan = (hwPlanId: string) => {
      confirm.require({
        message: '¿Estás seguro/a de eliminar esta clase?',
        header: '¡Alto ahí!',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          deleteHomeworkPlan(hwPlanId)
        },
        reject: () => {
          confirm.close()
        }
      })
    }

    const createHomeworkPlan = async (dto: Partial<HomeworkPlan>) => {
      const hwPlan:Partial<HomeworkPlan> = { ...dto }
      hwPlan.classPlanId = selectedClassPlan.value?._id
      try {
        await HomeworkPlanAPI.create(hwPlan)
        await loadHomeworks()
      } catch (e) {
        console.error(e)
      }
    }

    const reorderClassAgenda = async (params: [mapper: Map<string, ReorderValue>, modifyClass: ClassPlan]) => {
      const dto: ReorderEntity[] = []
      for (const [k, v] of params[0]) {
        dto.push({ id: k, finalOrder: v.finalOrder })
      }
      try {
        await ClassPlanAPI.reorderAgenda(dto)
        toast.add({
          severity: ToastSeverities.Success,
          detail: `¡Orden de la clase "${params[1].name}" modificado!`,
          life: 1500
        })
      } catch (e) {
        console.error(e)
        toast.add({
          severity: ToastSeverities.Error,
          detail: 'Error al modificar orden de clases 😪😪',
          life: 1500
        })
      } finally {
        classPlans.value = await ClassPlanAPI.findAll(syllabusId as string)
      }
    }

    return {
      coursePlan,
      syllabus,
      classPlans,
      loading,
      isActiveSyllabus,
      selectedClassPlan,
      selectedHomeworkPlans,
      loadingHomeworks,
      displayClassModal,
      displayHomeworkModal,
      assignOrderAndCreateClassPlan,
      onEditClassPlan: (ev: ClassPlan) => {
        editClassTarget.value = { ...ev }
        displayClassModal.value = true
      },
      onEditHw: (ev: HomeworkPlan) => {
        editHwTarget.value = { ...ev }
        displayHomeworkModal.value = true
      },
      showConfirmDeleteClassPlan,
      editClassPlan,
      editClassTarget,
      createHomeworkPlan,
      editHwTarget,
      showConfirmDeleteHomeworkPlan,
      editHomeworkPlan,
      reorderClassAgenda
    }
  }
})
