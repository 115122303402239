
import { Syllabus } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { Country } from '@/models/countries/classes/Country'
import { CountriesFlags } from '@/models/countries/enums'
import { useCountriesStore } from '@/store/modules/commons/countries-store'
import { computed, defineComponent, PropType } from 'vue'
import { TypeModality } from '@/models/academic-definitions/enums'

export default defineComponent({
  props: {
    syllabus: {
      type: Object as PropType<Syllabus>
    }
  },
  setup () {
    const countriesStore = useCountriesStore()
    const countriesActive = computed(() => countriesStore.countries)
    return {
      countriesActive,
      Country,
      CountriesFlags,
      TypeModality,
      syllabusCountries: (syllabus: Syllabus) => {
        return syllabus.language ? countriesActive.value.filter(c => syllabus?.language?.includes(c.id)) : countriesActive.value
      }
    }
  }
})
