import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-285f8398")
const _hoisted_1 = { class: "p-grid p-nogutter p-jc-between" }
const _hoisted_2 = { class: "p-col-10" }
const _hoisted_3 = { class: "p-col-auto" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "p-p-0"
}
const _hoisted_6 = { key: 1 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_BrainSpinner = _resolveComponent("BrainSpinner")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, null, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.selectedClassPlan.name), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('openHomeworkModal'))),
            class: "p-button-text",
            icon: "pi pi-plus"
          })
        ])
      ])
    ]),
    content: _withCtx(() => [
      (_ctx.loadingHomeworks)
        ? (_openBlock(), _createBlock(_component_BrainSpinner, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.homeworkPlans && _ctx.homeworkPlans.length !== 0)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.homeworkPlans, (hw) => {
                    return (_openBlock(), _createElementBlock("li", {
                      class: "p-d-flex p-jc-between p-ai-center",
                      style: {"height":"40px"},
                      key: hw
                    }, [
                      _createElementVNode("u", null, _toDisplayString(hw.name), 1),
                      _createVNode(_component_Button, {
                        onClick: ($event: any) => (_ctx.toggleMenu($event, hw)),
                        class: "p-button-text",
                        icon: "pi pi-ellipsis-h"
                      }, null, 8, ["onClick"]),
                      _createVNode(_component_Menu, {
                        ref: "menuRef",
                        model: _ctx.items,
                        popup: true
                      }, null, 8, ["model"])
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("p", _hoisted_6, " No hay desafios para esta clase. "))
          ]))
    ]),
    _: 1
  }))
}