import { HomeworkPlan } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { Api } from '../api'
import { CRUD } from '../crud'

class HomeworkPlanApi implements CRUD<HomeworkPlan> {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  public async findAll(classPlanId: string): Promise<HomeworkPlan[]> {
    return await Api.get<HomeworkPlan[]>(
      `${this.baseURL}/v1/academic-definitions/class-plan/${classPlanId}/homework-plans`
    )
  }

  public async create(dto: Partial<HomeworkPlan>): Promise<HomeworkPlan> {
    return await Api.post(
      `${this.baseURL}/v1/academic-definitions/homework-plans`,
      dto
    )
  }

  public async delete(id: string): Promise<HomeworkPlan> {
    return await Api.del(
      `${this.baseURL}/v1/academic-definitions/homework-plans/${id}`
    )
  }

  public async update(
    dto: Partial<HomeworkPlan>,
    id: string
  ): Promise<HomeworkPlan> {
    return await Api.patch(
      `${this.baseURL}/v1/academic-definitions/homework-plans/${id}`,
      dto
    )
  }
}

export const HomeworkPlanAPI = new HomeworkPlanApi()
