
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { ClassPlan } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { useModalForm } from '@/hooks/useModalForm'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default defineComponent({
  props: {
    editingClassPlan: {
      type: Object as PropType<ClassPlan>,
      required: false
    }
  },
  setup (props, ctx) {
    const defaultFields = computed(() => props.editingClassPlan)
    const { modalTitle, onShow, onHide, formValues } = useModalForm({
      formInitialState: {
        id: undefined,
        _id: undefined,
        name: '',
        slug: '',
        order: null,
        syllabusId: undefined,
        folderLink: '',
        theoryLink: '',
        description: '',
        free: false,
        leveling: false,
        published: true
      },
      title: 'Nueva clase'
    })
    const validations = {
      name: { required }
    }

    watch(defaultFields, () => {
      if (defaultFields.value) {
        Object.assign(formValues, defaultFields.value)
      }
    })

    const v$ = useVuelidate(validations, formValues as { name: string })
    const submitted = ref<boolean>(false)

    return {
      formValues,
      modalTitle,
      submitted,
      v$,
      onSubmit: (isFormValid: boolean) => {
        submitted.value = true
        if (!isFormValid) {
          return
        }
        if (defaultFields.value) {
          ctx.emit('onEdit', formValues)
        } else {
          ctx.emit('onSave', formValues)
        }
        ctx.emit('update:visible', false)
      },
      onHide,
      onShow
    }
  }
})
