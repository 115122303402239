
import { defineComponent, PropType, ref } from 'vue'
import Menu from 'primevue/menu'
import {
  ClassPlan,
  HomeworkPlan
} from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { MenuItem } from 'primevue/menuitem'

export default defineComponent({
  components: {
    Menu
  },
  props: {
    selectedClassPlan: {
      type: Object as PropType<ClassPlan>
    },
    homeworkPlans: {
      type: Array as PropType<Array<HomeworkPlan>>
    },
    loadingHomeworks: {
      type: Boolean
    }
  },
  emits: ['openHomeworkModal', 'editHomework', 'deleteHomeworkPlan'],
  setup (_, ctx) {
    const menuRef = ref()
    const items = ref<MenuItem[]>([])
    return {
      menuRef,
      items,
      toggleMenu: (event: MouseEvent, hw: HomeworkPlan) => {
        items.value = [
          {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: () => {
              ctx.emit('editHomework', hw)
            }
          },
          {
            label: 'Eliminar',
            icon: 'pi pi-trash',
            command: () => {
              ctx.emit('deleteHomeworkPlan', hw.id)
            }
          }
        ] as MenuItem[]
        menuRef.value?.toggle(event)
      }
    }
  }
})
