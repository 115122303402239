import { ClassPlan } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { ReorderEntity } from '@/models/sorting/reorder'
import { Api } from '../api'
import { CRUD } from '../crud'

class ClassPlanApi implements CRUD<ClassPlan> {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  public async create(dto: Partial<ClassPlan>): Promise<ClassPlan> {
    return Api.post(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/classPlan/create`,
      dto
    )
  }

  public async findAll(syllabusId: string): Promise<ClassPlan[]> {
    return await Api.get(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/classPlan/${syllabusId}`
    )
  }

  public async delete(id: string): Promise<ClassPlan> {
    return await Api.del(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/classPlan/delete/${id}`
    )
  }

  public async update(dto: ClassPlan, id: string): Promise<ClassPlan> {
    return await Api.patch(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/classPlan/update/${id}`,
      dto
    )
  }

  public async reorderAgenda(dto: ReorderEntity[]): Promise<ClassPlan[]> {
    return await Api.patch(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/classPlan/reorder`,
      dto
    )
  }
}

export const ClassPlanAPI = new ClassPlanApi()
